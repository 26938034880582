<template>
  <div class="protocol-box">
    <div>
      <p>法律声明及隐私权政策</p>
      <div>
        <strong>提示条款</strong>
        <p>
          鸿联云提醒您：在使用鸿联云平台各项服务前，请您务必仔细阅读并透彻理解本《法律声明及隐私权政策》（下称“本声明”）。如对本声明内容有任何疑问，您可通过鸿联云客服与我们联系。阅读本声明的过程中，<span
            >如果您不同意本声明或其中任何内容，您应立即停止使用鸿联云平台服务。如果您使用鸿联云平台服务的，您的使用行为将被视为对本声明全部内容的认可。</span
          >
        </p>
        <p><span>更新日期：2022.4.13</span></p>
        <strong>法律声明</strong>
        <p><em>权利归属</em></p>
        <p>
          除非鸿联云另行声明，鸿联云平台内的所有产品、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档）的所有权利（包括但不限于版权、商标权、专利权、商业秘密及其他所有相关权利）均归鸿联云或其关联公司所有。未经鸿联云许可，任何人不得擅自使用（包括但不限于通过任何机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载鸿联云平台内的任何内容）。
        </p>
        <p>
          鸿联云平台的Logo、“鸿联云”、“鸿联云”等文字、图形及其组合，以及鸿联云平台的其他标识、徵记、产品和服务名称均为鸿联云及其关联公司在中国和其它国家的商标，<span
            >未经鸿联云书面授权，任何人不得以任何方式展示、使用或作其他处理，也不得向他人表明您有权展示、使用或作其他处理。</span
          >
        </p>
        <p><em>责任限制</em></p>
        <p>
          <span
            >鉴于鸿联云平台提供的服务的特殊性，鸿联云的店铺、商品信息（包括但不限于店铺名称、公司名称、
            联系人及联络信息、产品的描述和说明、相关图片、视频等）可由用户自行提供并上传，由用户对其提供并上传的所有信息承担相应法律责任。</span
          >
        </p>
        <p>
          鸿联云平台转载作品（包括论坛内容）出于传递更多信息之目的，并不意味鸿联云赞同其观点或已经证实其内容的真实性。
        </p>
        <em>知识产权保护</em>
        <p>
          <span>
            鸿联云尊重知识产权，反对侵权盗版行为。知识产权权利人认为鸿联云平台内容（包括但不限于鸿联云平台用户发布的商品信息）可能涉嫌侵犯其合法权益，可以通过鸿联云平台客服提出书面通知，鸿联云收到后将及时处理。
          </span>
        </p>
        <strong>隐私权政策</strong>
        <p>
          鸿联云（下称“我们”）尊重并保护用户隐私，在您使用鸿联云平台提供的服务时，我们将按照本隐私权政策收集、使用及共享您的个人信息。本隐私权政策包含了我们收集、存储、使用、共享和保护您的个人信息的条款，我们希望通过本隐私权政策向您清晰地介绍我们对您个人信息的处理方式，因此我们建议您完整地阅读本隐私权政策，以帮助您了解维护自己隐私权的方式。
        </p>
        <p>
          <span>
            如果您不同意本隐私权政策任何内容，您应立即停止使用鸿联云平台服务。当您使用鸿联云平台提供的任一服务时，即表示您已同意我们按照本隐私权政策来合法使用和保护您的个人信息。
          </span>
        </p>
        <p><em>适用范围</em></p>
        <p>
          为用户提供更好、更优、更个性化的服务是鸿联云坚持不懈的追求，也希望通过我们提供的服务可以更方便您的生活。本隐私权政策适用于鸿联云平台提供的所有服务，您访问鸿联云平台及未设独立隐私权政策的关联公司网站及/或登陆相关客户端使用鸿联云平台提供的服务，均适用本隐私权政策。
        </p>
        <p>
          需要特别说明的是，本隐私权政策不适用于其他第三方向您提供的服务，例如鸿联云上的服务商依托鸿联云平台向您提供服务时，您向服务商提供的个人信息不适用于本隐私权政策。
        </p>
        <p><em>我们收集哪些信息</em></p>
        <p>
          为了向您提供更好、更优、更个性化的服务，我们将根据您使用的服务收集您的如下信息：
        </p>
        <p>1.您向我们提供的信息</p>
        <p>
          当您在鸿联云平台创建账户时，您必须至少向我们提供用户名、电话号码及/或电子邮箱等信息，并创建密码。
        </p>
        <p>
          您在使用鸿联云平台时，需要根据服务的内容，选择填写及/或提交服务所必须的信息，可能包括您的姓名、性别、出生年月日、身份证号码、护照姓、护照名、护照号码、电话号码、电子邮箱、地址、鸿联云账号及相关附加信息（如您所在的省份和城市、邮政编码等）。如果您不使用某些服务，则无需提供相关信息。
        </p>
        <p>
          我们还会记录您在使用我们服务时提供的信息。例如您在鸿联云发布的信息、搜索关键词信息、与鸿联云客服联系时您提供的相关信息、您参与问卷调查时向我们发送的问卷答复信息等。
        </p>
        <p>2.在您使用服务过程中收集的信息</p>
        <p>
          我们会根据您授予的权限，接收并记录您所使用的设备相关信息，这些设备信息包括：
        </p>
        <p>
          设备属性信息（如设备型号、操作系统版本、设备设置、唯一设备标识符等软硬件特征信息）、设备位置信息（如通过GPS、蓝牙或WIFI信号获得的位置信息）、设备连接信息（IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间及您需求的网页记录等）。
        </p>
        <p>
          对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务
        </p>
        <p>
          此外，为提高您使用鸿联云平台提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能根据您的授权，通过了解一些您的网络使用习惯、您常用的软件信息等手段来判断您账户的风险，并可能会记录一些我们认为有风险的URL。
        </p>
        <p>3.来自第三方的信息</p>
        <p>
          当您与使用我们服务的关联公司、鸿联云合作伙伴之间互动时，为共同向您提供服务及改进服务质量的合理需要，我们将从关联公司、合作伙伴处接收关于您的体验或您与其互动的信息。
        </p>
        <p>
          为预防欺诈和确保安全，鸿联云的关联公司、合作伙伴会依据适用的法律法规或征得您同意的前提下，向我们分享您的信息。
        </p>
        <em>我们如何使用收集到的信息</em>
        <p>
          收集您的信息是为了向您提供服务及提升服务质量，为了实现这一目的，我们会把您的信息用于下列用途：
        </p>
        <p>1.向您提供您使用的各项服务，并维护、改进这些服务。</p>
        <p>
          2.向您推荐您可能感兴趣的内容，包括但不限于向您发出产品和服务信息，或通过系统向您展示个性化的第三方推广信息，或者在征得您同意的情况下与鸿联云的合作伙伴共享信息以便他们向您发送有关其产品和服务的信息。如您不希望接收上述信息，可通过相应的退订功能进行退订。
        </p>
        <p>
          3.我们可能使用您的个人信息以预防、发现、调查欺诈、危害安全、非法或违反与我们或其关联方协议、政策或规则的行为，以保护您、我们的其他用户、我们或我们关联方的合法权益。
        </p>
        <p>
          4.我们可能会将来自某项服务的信息与来自其他服务的信息结合起来，以便为您提供服务、个性化内容和建议。
        </p>
        <p>5.经您许可的其他用途。</p>
        <p><em>您如何管理自己的信息</em></p>
        <p>
          我们提供了便捷的方法，让您可以查询、更正及删除自己的账户信息等使用我们服务时您提供的个人信息。例如您可以登录“我是服务商”“我的账号”等查询、修改自己的个人资料、隐私设置、安全设置，删除您的收货地址、交易订单等信息或注销您的鸿联云账户。
        </p>
        <p>
          如果您认为鸿联云存在违反法律、行政法规的规定或者与您的约定收集、使用您个人信息的，您可以通过鸿联云客服与我们取得联系。
        </p>
        <p><em>您共享的信息</em></p>
        <p>
          您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您的交易内容、联络方式、交流信息或分享内容等相关信息的第三方。
        </p>
        <p>
          在使用鸿联云平台服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是您的账户或密码发生泄露，请您立即联络鸿联云客服，以便鸿联云采取相应措施。
        </p>
        <p>
          公开信息是指您公开分享的任何信息。任何人都可以在使用和未使用鸿联云期间查看或访问这些信息。
        </p>
        <p>
          为使用鸿联云服务，可能存在您必须公开分享的信息。例如为构建诚信交易环境，您的信用评价信息需要被公开分享。若您是服务商，您应当根据适用的法律法规和鸿联云规则的要求，公开分享公司名称等相关工商注册信息或者自然人经营者的信息。
        </p>
        <p>
          请注意，您在使用我们服务时自愿共享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在评价时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。
        </p>
        <p><em>我们如何共享信息</em></p>
        <p>
          我们对您的信息承担保密义务，不会出于第三方的营销目的而向其出售或提供您的任何信息，除下列情形外，我们不会与任何其他第三方共享您的个人信息：
        </p>
        <p>
          1.事先获得授权。获得您的授权后，我们会与其他公司、组织和个人分享您的个人信息。只有您明确同意，我们才会共享您的部分敏感信息。
        </p>
        <p>
          2.向鸿联云的关联公司分享您的个人信息。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。
        </p>
        <p>
          3.出于法律原因。为遵守适用的法律法规、诉讼目的或者行政机关、司法机构依法提出的要求，我们可能有必要披露您的个人信息。如果我们确定您出现违反适用的法律法规或鸿联云规则或相关协议的情况，或为保护鸿联云及其关联公司或用户或公众的权利、财产或安全免遭损害，我们也可能披露关于您的信息，包括相关违规行为以及鸿联云已对您采取的措施。
        </p>
        <p>
          4.用于外部处理。为实现本隐私权政策中声明的目的，我们可能让可信赖的合作伙伴作为数据处理者，根据我们的指示并遵循我们的隐私权政策以及其他任何相应的保密和安全措施来为我们处理您的个人信息。
        </p>
        <p>本公司采用华为云存储数据</p>
        <p>
          5.协助解决争议。某些情况下只有共享您的信息，才能处理您与他人的纠纷或争议。例如您是适格的知识产权投诉人并已提起投诉，我们可能应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；您在鸿联云上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，鸿联云会视情况向该用户提供其交易对方的联络方式等必要信息，以促成纠纷的解决。
        </p>
        <p><em>Cookie和类似技术的使用</em></p>
        <p>
          为使您获得更轻松的访问体验，您访问鸿联云平台相关网站或使用鸿联云平台提供的服务时，我们可能会通过采用各种技术收集和存储相关信息，包括使用小型数据文件识别您的身份，这么做是为了解您的使用习惯，帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie、Flash
          Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。
        </p>
        <p>
          请您理解，我们的某些服务只能通过使用Cookie才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝鸿联云的Cookie，但拒绝鸿联云的Cookie在某些情况下可能会影响您安全访问鸿联云平台相关网站和使用鸿联云平台提供的服务。
        </p>
        <p>
          网页上常会包含一些电子图象（称为“单像素 GIF 文件”或“网络
          beacon”），使用网络beacon可以帮助网站计算浏览网页的用户或访问某些cookie，我们会通过网络beacon收集您浏览网页活动信息，例如您访问的页面地址、您先前访问的援引页面的位址、您停留在页面的时间、您的浏览环境以及显示设定等。
        </p>
        <p><em>我们如何存留信息</em></p>
        <p>
          鸿联云收集的有关您的信息和资料将保存在鸿联云及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或鸿联云收集信息和资料所在地并在该地被访问、存储和展示。
        </p>
        <p>
          <span
            >鸿联云采用华为云存储数据，鸿联云恪守对用户的安全承诺，根据适用的法律保护用户存储在华为云数据中心的数据。</span
          >
        </p>
        <p>
          我们将在实现本隐私政策中所述目的所必需的期间内保留你的个人信息，除非法律要求或允许在更长的期间内保留这些信息。
        </p>
        <p><em>您的个人信息保护</em></p>
        <p>
          鸿联云非常重视您的个人信息安全。我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的个人信息，并尽最大合理努力使您的个人信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到您个人信息的员工或外包人员也采取了严格管理，包括但不限于采取信息访问权限控制、与接触个人信息的人员签署保密协议、监控该等人员的操作情况等措施。
        </p>
        <p>
          鸿联云账户信息（包括账号名、密码、支付密码、实名认证信息等）为非常重要的个人信息，请您妥善设置、保管您的鸿联云账号名及密码信息。鸿联云将通过备份、对密码进行不可逆加密等安全措施以保护您的账户信息等个人信息不丢失、不被滥用和变造。尽管有前述安全措施，但同时也请您理解在信息网络上不存在“完善的安全措施”。
        </p>
        <p>
          <span
            >请注意，单独的设备信息、搜索关键词信息等是无法与任何特定个人直接建立联系的数据，不属于个人信息。如果我们将这类非个人信息与其他信息结合用于识别自然人个人身份，或者将其与个人信息结合使用，则在结合使用期间，此类设备信息将被视为个人信息</span
          >
        </p>
        <p><em>未成年人保护</em></p>
        <p>
          我们重视未成年人的个人信息保护，如您为未成年人，建议您请您的监护人仔细阅读本隐私权政策，并在征得您的监护人同意的前提下使用我们的服务或向我们提供信息。
        </p>
        <p><em>隐私权政策的更新</em></p>
        <p>
          <span
            >我们可不定期修改本《法律声明及隐私权政策》，变更后的《法律声明及隐私权政策》将在修订生效前通过鸿联云平台公告或以其他适当方式通知您。该等情况下，若您继续使用我们的服务，即表示同意受经修订的《法律声明及隐私权政策》的约束。</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.protocol-box {
  padding: 16px;
  >div{
    margin: 0 auto;
    background: #fff;
    >p{
        text-align: center;
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: bold;
    }
    >div{
        >strong{
            font-size: 16px;
            line-height: 22px;
            display: block;
            margin-bottom: 16px;
        }
        >em{
            font-size: 16px;
            line-height: 22px;
            display: block;
            margin-bottom: 16px;
            font-style: initial;
            font-weight: bold;
        }
        >p{
            font-size: 14px;
            line-height: 20px;
            text-indent: 2em;
            margin-bottom: 20px;
            >span{
                text-decoration: underline;
            }
            >em{
              font-style: initial;
              font-weight: bold;
            }
        }
    }
  }
}
</style>
